import React, { useState } from 'react';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzText,
    BringzzImage,
    BringzzPostingCard,
    BringzzButton, BringzzBottomDrawer,
    BringzzRotatingSelect,
    BringzzArticle,
    BringzzInput,
    BringzzDate,
    Mode
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import AvatarExample from 'assets/images/AvatarExample.png';
import usePageState from 'hooks/usePageState';
import { useAuth } from 'context/AuthContext';
import { hoursOptions, minutesOptions } from 'utils/constants';
import { formatDateToDDMMYYYY, parseToMySQLDateTime } from 'utils';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const PreviewArticlePage = () => {
    const { backPress } = useNavigation();
    let query = useQuery();
    const { user } = useAuth();
    const { navigate } = useNavigation();
    const [currentHours, setCurrentHours] = useState(null);
    const [currentMinutes, setCurrentMinutes] = useState(null);
    const [selectingTime, setSelectingTime] = useState(false);
    const [scheduledTime, setScheduledTime] = useState(null);
    const [currentDate, setCurrentDate] = useState(formatDateToDDMMYYYY(new Date()))
    const { sendRequest, loading } = useRequest();

    const formData = usePageState();

    const changeHours = result => {
        setCurrentHours(result.value);
    };
    const changeMinutes = result => {
        setCurrentMinutes(result.value);
    };
    const closeTimeSelection = () => {
        const time = `${currentHours}:${currentMinutes.toString().padStart(2, '0')}`;
        setScheduledTime(`${currentDate} ${time}`);
        setSelectingTime(false);
    };

    const onSubmit = async () => {
        const data = {
            "type": 1,
            "headline": formData.headline,
            "images": [
                {
                    "content": formData.photo
                }
            ],
            "components": formData.blocks,
            "description": formData.leadIn,
            "release": "",
            "tags": formData.tags,
            "imageCaption": formData.imageCaption,
            "published": !scheduledTime
        }

        if (scheduledTime) {
            data['release'] = parseToMySQLDateTime(scheduledTime)
        }

        await sendRequest({
            method: 'POST',
            url: `/module/post/createPost`,
            data
        }).then((response) => {
            if (response.status === 'success')
                navigate(`/posts/${scheduledTime ? 'scheduled' : 'published'}/article`)
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <BringzzPageContainer
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),

                title: <BringzzText tag='h2'>Preview Article</BringzzText>
            }}
            footer={
                <div className='pb-28 px-6 flex flex-row space-x-2'>
                    <BringzzButton
                        onClick={onSubmit}
                        size='md'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        disabled={loading}
                    >
                        Share {scheduledTime ? `| ${scheduledTime}` : ''}
                    </BringzzButton>
                    <div className='flex space-x-1'>
                        <BringzzButton disabled={loading} className='!p-0' onClick={() => setSelectingTime(true)}>
                            <BringzzIcon
                                folder='LineIcons'
                                className='bg-magic-lilac text-midnight-blue p-2 rounded-full cursor-pointer'
                                size='20'
                                icon='IconClockAlt1'
                            />
                        </BringzzButton>
                    </div>
                </div>
            }
        >
            <div className=''>
                <BringzzArticle
                    title={formData.headline || ''}
                    subTitle={formData.leadIn || ''}
                    likesCount={100}
                    commentsCount={10}
                    interactionBar={false}
                />

                <div className="flex items-center space-x-2 px-4">
                    <img src={AvatarExample} className="h-8 w-8" alt="avatar" />
                    <BringzzText
                        tag="h4"
                        className={"font-bold"}
                    >
                        {user.fullName}
                    </BringzzText>
                    <div className='flex items-center space-x-1'>
                        <span className="text-[5px]">&#9679;</span>
                        <BringzzText
                            tag="h4"
                            className={'font-normal text-magic-lilac'}
                        >
                            Follow
                        </BringzzText>
                    </div>
                </div>

                <div className='mt-3'>
                    <BringzzImage src={formData.photo} alt='article image' className='h-56 w-full' />
                    <BringzzText className={'font-normal italic p-3'} tag='h5'>{formData.imageCaption}</BringzzText>
                </div>
                {formData.blocks && formData.blocks.map((block, index) => {
                    return <div key={index} className='px-3'>
                        {block.component == "body_copy" && <BringzzText tag='h4'>{block.content}</BringzzText>}
                        {block.component == "inline_heading" && <BringzzText tag='h2'>{block.content}</BringzzText>}
                    </div>
                })}
            </div>
            <BringzzBottomDrawer
                isOpen={selectingTime}
                close={closeTimeSelection}
                title='Schedule Post'
            >
                <div className='p-4'>
                    <BringzzDate
                        placeholder={"Pick date"}
                        format="dd/MM/yyyy"
                        onChange={(newDate) => setCurrentDate(newDate)}
                        mode={Mode.DAY}
                        minDate={new Date()}
                    ></BringzzDate>
                </div>
                <div className='flex items-center relative gap-x-4 justify-center'>
                    <div className='h-12 absolute top-1/2 right-0 w-full border-y -translate-y-1/2'></div>
                    <div>
                        <BringzzRotatingSelect
                            options={hoursOptions}
                            onSelect={changeHours}
                        />
                    </div>
                    <div>
                        <BringzzRotatingSelect
                            options={minutesOptions}
                            onSelect={changeMinutes}
                        />
                    </div>
                </div>

                <div className='flex justify-center items-center py-6'>
                    <BringzzButton
                        size='lg'

                        className='bg-magic-lilac '
                        onClick={closeTimeSelection}
                    >
                        Apply
                    </BringzzButton>
                </div>
            </BringzzBottomDrawer>
        </BringzzPageContainer>

    );
};

export default PreviewArticlePage;
