import React from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { useNotification } from '../../context/NotificationContext';

import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzList } from "@bringzz/components"

const NotificationsPage = () => {
    const { backPress } = useNavigation();
    const {notifications} = useNotification()
    
    return (
        <BringzzPageContainer
            header={{
                right: <BringzzIcon
                    size="18"
                    icon="IconClose"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Notifications</BringzzText>,
            }}>

            <BringzzList type="notifications" items={notifications.map(notification => {return {id: notification.id,title: notification.title,body: notification.content}})}></BringzzList>

        </BringzzPageContainer>

    )
}

export default NotificationsPage;