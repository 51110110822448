import React from 'react';
import Navbar from '../components/common/Navbar';
import { Outlet } from 'react-router-dom';
import ProgressOverlay from 'components/ProgressOverlay';
import CameraOverlay from 'components/CameraOverlay';

const MobileLayout = () => {
	return (
		<div className='h-screen'>
			<main className='pb-24 min-h-screen h-full'>
				<Outlet />
				<ProgressOverlay />
				{/* <CameraOverlay /> */}
			</main>
			<Navbar />
		</div>
	);
};

export default MobileLayout;
