import { DefaultMenuList, PharmacistMenuList } from './constants';

export const returnMenuList = (role) => {
    if (role === 1) {
        return PharmacistMenuList
    } else {
        return DefaultMenuList
    }
}

export const handleMenuNavigation = (item, navigate, setPickerDrawerOpened) => {
    if (item.label === "Post") {
        setPickerDrawerOpened(true);
    } else {
        navigate(item.url);
    }
};

export const parseToMySQLDateTime = (dateString) => {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [hours, minutes] = timePart ? timePart.split(':').map(Number) : [0, 0]; // If time is missing, default to 00:00
    const date = new Date(year, month - 1, day, hours, minutes);
    const formattedDate = date.toISOString().slice(0, 19).replace('T', ' ');

    return formattedDate;
}

export const formatDateToDDMMYYYY = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

export const isValidURL = (url) => {
    const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/[^\s]*)?$/;
    return regex.test(url);
}

export const compressImageToBase64 = async (imageUrl, maxWidth = 800, maxHeight = 800, quality = 0.7) => {
    return new Promise((resolve, reject) => {
        // Create an image element
        const img = new Image();

        // When the image is loaded, compress it
        img.onload = function () {
            // Create a canvas element
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            let width = img.width;
            let height = img.height;

            // Maintain aspect ratio while resizing
            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            // Set canvas dimensions
            canvas.width = width;
            canvas.height = height;

            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0, width, height);

            // Compress and convert to base64
            const base64String = canvas.toDataURL('image/jpeg', quality);

            resolve(base64String); // Return the Base64 string
        };

        // Handle errors
        img.onerror = function (err) {
            reject(`Failed to load image: ${err}`);
        };

        // Set the source of the image to the provided URL
        img.crossOrigin = "Anonymous"; // This enables cross-origin requests for images
        img.src = imageUrl;
    });
}
