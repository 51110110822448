import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { useSocket } from './SocketContext';
import useRequest from 'hooks/useRequest';

// Create the context
const ProgressOverlayContext = createContext();

// Provide the context to the app
export const ProgressOverlayProvider = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);  // Controls if overlay is visible
    const [activeStep, setActiveStep] = useState(0);    // Controls the current step
    const { sendMessage, onRefetchData } = useSocket();
    const { sendRequest, data, error, loading } = useRequest();

    // Method to show the overlay and start at a specific step
    const showOverlay = useCallback((initialStep = 0) => {
        setActiveStep(initialStep);
        setIsVisible(true);
    }, []);

    // Method to hide the overlay
    const hideOverlay = useCallback(() => {
        setIsVisible(false);
    }, []);

    // Method to increment the current step
    const nextStep = useCallback(() => {
        setActiveStep(prevStep => prevStep + 1);
    }, []);

    // Method to reset steps
    const resetSteps = useCallback(() => {
        setActiveStep(0);
    }, []);

    useEffect(() => {
        const cleanupRefetchData = onRefetchData((data) => {
            if (data) {
                console.log({ data });
                if (data.notificationType.includes("ORDER_PROGRESS_")) return setActiveStep(data.notificationType.split("_")[2]);
            }
            return;
        });

        return () => {
            if (cleanupRefetchData) {
                cleanupRefetchData();
            }
        };
    }, [onRefetchData])

    return (
        <ProgressOverlayContext.Provider value={{ isVisible, activeStep, showOverlay, hideOverlay, nextStep, resetSteps }}>
            {children}
        </ProgressOverlayContext.Provider>
    );
};

// Hook to use the context
export const useProgressOverlay = () => {
    return useContext(ProgressOverlayContext);
};
